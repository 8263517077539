import Dashboard from 'components/Icon/Dashboard';
import {HiHome, HiShieldCheck} from 'react-icons/hi2';
import Convert from 'components/Icon/Convert';
import {MdOutlineAlignVerticalTop} from 'react-icons/md';
import {MdAlignVerticalBottom} from 'react-icons/md';
import {HiOutlineDocumentReport} from 'react-icons/hi';
import {LuShieldClose} from 'react-icons/lu';
import {GiProfit} from 'react-icons/gi';
import {TbDeviceAnalytics} from 'react-icons/tb';
import {GiLightningFrequency} from 'react-icons/gi';
import {RiSkypeLine} from 'react-icons/ri';
import {GiTrade} from 'react-icons/gi';
import {TbMenuOrder} from 'react-icons/tb';
import { MdSwitchAccount } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
// import {MdDashboard as Dashboard} from 'react-icons/md';
function path(root, sublink) {
    return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_APP = '/app';
export const ROOTS_ADMIN = '/admin';

export const ROOTS_DEV = '/dev';

export const devPaths = {
    root: ROOTS_DEV,
    apis: path(ROOTS_DEV, '/api-explorer'),
};

export const authPaths = {
    //auth
    login: path(ROOTS_AUTH, '/login'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    signUp: path(ROOTS_AUTH, '/sign-up'),
    signUpVerify: path(ROOTS_AUTH, '/sign-up-verify'),
    logout: path(ROOTS_AUTH, '/logout'),
};

export const paths = {
    root: ROOTS_APP,
    redirector: path('', '/redirector'),
    //app
    dashboard: path(ROOTS_APP, '/dashboard'),
    profile: path(ROOTS_APP, '/profile'),
    notificationSetting: path(ROOTS_APP, '/profile/notification'),
    profileKycReview: path(ROOTS_APP, '/profile/kyc'),
    profileCryptoWallet: path(ROOTS_APP, '/profile/crypto-wallet'),
    preference: path(ROOTS_APP, '/profile/preference'),
    security: path(ROOTS_APP, '/profile/security'),
    settings: path(ROOTS_APP, '/settings'),
    components: path(ROOTS_APP, '/components'),
    //kyc paths
    kyc: path(ROOTS_APP, '/kyc'),
    kyb: path(ROOTS_APP, '/kyb'),
    //help center
    helpCenter: path(ROOTS_APP, '/help-center'),
    support: path(ROOTS_APP, '/help-center/support'),
    supportDetail: (id) => path(ROOTS_APP, `/help-center/support/${id}`),
    newSupport: path(ROOTS_APP, '/help-center/support/newSupport'),
    QAndA: path(ROOTS_APP, '/help-center/q-and-a'),

    //api
    apiAccess: path(ROOTS_APP, '/apis'),
    apis: path(ROOTS_APP, '/apis/api-explorer'),
    apiLog: path(ROOTS_APP, '/apis/api-log'),
    apiKey: path(ROOTS_APP, '/apis/api-key'),

    //referral
    referralRoot: path(ROOTS_APP, '/referral'),
    referees: path(ROOTS_APP, '/referral/referees'),
    referralCommission: path(ROOTS_APP, '/referral/commission'),

    //funding
    funding: path(ROOTS_APP, '/funding'),
    fundingSummary: path(ROOTS_APP, '/funding/funding-summary'),
    deposits: path(ROOTS_APP, '/funding/deposits'),
    newDeposit: path(ROOTS_APP, '/funding/newDeposit'),
    withdrawals: path(ROOTS_APP, '/funding/withdrawals'),
    creatWithdrawal: path(ROOTS_APP, '/funding/withdrawals/creatWithdrawal'),
    withdrawalSuccess: path(
        ROOTS_APP,
        '/funding/withdrawals/withdrawalSuccess',
    ),
    transfers: path(ROOTS_APP, '/funding/transfers'),
    creatTransfer: path(ROOTS_APP, '/funding/transfers/creatTransfer'),
    transferSuccess: path(ROOTS_APP, '/funding/transfers/transferSuccess'),
    commissions: path(ROOTS_APP, '/funding/commissions'),
    moneyMove: path(ROOTS_APP, '/funding/moneyMove'),
    withdrawalAccounts: path(ROOTS_APP, '/funding/funding-withdrawal-accounts'),
    statements: path(ROOTS_APP, '/funding/statements'),

    channelDepositComplete: path(
        ROOTS_APP,
        '/funding/deposits/channel-deposit-complete',
    ),
    channelDepositCancel: path(
        ROOTS_APP,
        '/funding/deposits/channel-deposit-cancel',
    ),

    //accounts
    accounts: path(ROOTS_APP, '/accounts'),
    accountsWithTypeId: (type, id) =>
        path(ROOTS_APP, `/accounts?type=${type}&accountId=${id}`),

    //broker
    ibAgent: path(ROOTS_APP, '/ib-agent'),
    ibOverview: path(ROOTS_APP, '/ib-agent/ib-overview'),
    ibSublevels: path(ROOTS_APP, '/ib-agent/ib-sublevels'),
    pendingInvitations: path(ROOTS_APP, '/ib-agent/pending-invitations'),
    // ib
    ib: path(ROOTS_APP, '/ib-agent'),

    newsPage: path(ROOTS_APP, '/newsPage'),
    //news
    news: path(ROOTS_APP, '/newsPage/news'),
    //news
    announcements: path(ROOTS_APP, '/newsPage/announcements'),

    //tools
    tools: path(ROOTS_APP, '/tools'),
    downloads: path(ROOTS_APP, '/downloads'),

    //help
    help: path(ROOTS_APP, '/help'),

    //About us
    aboutUs: path(ROOTS_APP, '/aboutUs'),

    //About us
    notification: path(ROOTS_APP, '/notification'),
};

export const adminPaths = {
    root: ROOTS_ADMIN,
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    settings: path(ROOTS_ADMIN, '/settings'),

    accounts: path(ROOTS_ADMIN, '/clients-accounts'),
    mtAccounts: path(ROOTS_ADMIN, '/clients-accounts/mt-account'),
    accountMonitor: path(ROOTS_ADMIN, '/clients-accounts/monitor-account'),
    emailMonitor:  path(ROOTS_ADMIN, '/clients-accounts/email-monitor'),
    accountAssessment: path(ROOTS_ADMIN, '/clients-accounts/account-assessment'),

    //profile
    profile: path(ROOTS_ADMIN, '/profile'),
    notificationSetting: path(ROOTS_ADMIN, '/profile/notification'),
    preference: path(ROOTS_ADMIN, '/profile/preference'),
    security: path(ROOTS_ADMIN, '/profile/security'),
    404: '/404',
    403: '/403',

    tradeAnalysis: path(ROOTS_ADMIN, '/trade-analysis'),
    absoluteHedged: path(ROOTS_ADMIN, '/trade-analysis/absolute-hedged'),
    potencialHedgedAcross: path(ROOTS_ADMIN, '/trade-analysis/across-hedged'),
    potencialHedgedPlatform: path(
        ROOTS_ADMIN,
        '/trade-analysis/platform-hedged',
    ),
    riskMtTradeMonitor: path(ROOTS_ADMIN, '/trade-analysis/mt-monitor'),
    closedMtMonitor: path(ROOTS_ADMIN, '/trade-analysis/closed-monitor'),
    highFrequency: path(ROOTS_ADMIN, '/trade-analysis/high-frequency'),
    profitOver500USD: path(ROOTS_ADMIN, '/trade-analysis/profit-over-currency'),
    closedIn300Sec: path(ROOTS_ADMIN, '/trade-analysis/closed-in-second'),
    openIn600Sec: path(ROOTS_ADMIN, '/trade-analysis/open-in-second'),

    pnlAnalysis: path(ROOTS_ADMIN, '/pnl-analysis'),
    highGainAnalysis: path(ROOTS_ADMIN, '/pnl-analysis/high-gain'),
    profitsAnalysis: path(ROOTS_ADMIN, '/pnl-analysis/profits-analysis'),
    commissionAnalysis: path(ROOTS_ADMIN, '/pnl-analysis/commission-analysis'),
    dailyProfitOver500USD: path(
        ROOTS_ADMIN,
        '/pnl-analysis/daily-profit-over-currency',
    ),
    profitableTradesClosed: path(
        ROOTS_ADMIN,
        '/pnl-analysis/profitable-trades-closed',
    ),
    dailyProfitOverPerc: path(
        ROOTS_ADMIN,
        '/pnl-analysis/daily-profit-over-perc',
    ),

    lowGainAnalysis: path(ROOTS_ADMIN, '/risk-management/low-gain'),
    hedgedPositions: path(ROOTS_ADMIN, '/risk-management/hedged-positions'),
    eaOpened: path(ROOTS_ADMIN, '/risk-management/ea-opened'),
    riskyAccounts: path(ROOTS_ADMIN, '/risk-management/risky-account'),
    // risk report
    riskReports: path(ROOTS_ADMIN, '/risk-management/risk-reports'),
    salesReports: path(
        ROOTS_ADMIN,
        '/risk-management/risk-reports/sales-reports',
    ),

    //notification
    notification: path(ROOTS_ADMIN, '/notification'),

    settingsExcluded: path(ROOTS_ADMIN, '/settings/setting-excluded'),
    settingsExcludedGroups: path(
        ROOTS_ADMIN,
        '/settings/setting-excluded/groups',
    ),
    settingsExcludedAccounts: path(
        ROOTS_ADMIN,
        '/settings/setting-excluded/accounts',
    ),
    settingsRiskDefinitions: path(
        ROOTS_ADMIN,
        '/settings/setting-risk-definitions',
    ),
    settingsNotification: path(ROOTS_ADMIN, '/settings/setting-notification'),
    settingsRiskAdministrator: path(
        ROOTS_ADMIN,
        '/settings/risk-administrator',
    ),
};

export const PATH_AFTER_LOGIN = paths.dashboard;
export const PATH_AFTER_ADMIN_LOGIN = adminPaths.dashboard;
export const PATH_TO_REDIRECTOR = paths.redirector;

export const navDrawerConfig = ({
    isIB,
    allowDevelop,
    isCompany,
    platformBasicInfo,
}) => [];

export const navDrawerAdminConfig = (getPermission) => [
    // {
    //     labelKey: 'dashboard',
    //     value: 'dashboard',
    //     path: adminPaths.dashboard,
    //     Icon: HiHome,
    //     kycValid: false,
    // },
    {
        labelKey: 'accounts',
        value: 'accounts',
        path: adminPaths.accounts,
        subMenu: true,
        Icon: MdSwitchAccount,
        paths: [
            {
                labelKey: 'mtAccounts',
                value: 'mtAccounts',
                path: adminPaths.mtAccounts,
            },
            {
                labelKey: 'accountMonitor',
                value: 'accountMonitor',
                path: adminPaths.accountMonitor,
            },
            {
                labelKey: 'emailMonitor',
                value: 'emailMonitor',
                path: adminPaths.emailMonitor,
            },
            {
                labelKey: 'accountAssessment',
                value: 'accountAssessment',
                path: adminPaths.accountAssessment,
            },
        ],
    },
    {
        labelKey: 'tradeAnalysis',
        value: 'tradeAnalysis',
        path: adminPaths.tradeAnalysis,
        subMenu: true,
        Icon: GiTrade,
        paths: [
            {
                labelKey: 'riskMtTradeMonitor',
                value: 'riskMtTradeMonitor',
                path: adminPaths.riskMtTradeMonitor,
            },
            {
                labelKey: 'closedMonitor',
                value: 'closedMonitor',
                path: adminPaths.closedMtMonitor,
            },
            {
                labelKey: 'absoluteHedged',
                value: 'absoluteHedged',
                path: adminPaths.absoluteHedged,
            },
            {
                labelKey: 'potencialHedgedAcross',
                value: 'potencialHedgedAcross',
                path: adminPaths.potencialHedgedAcross,
            },
            {
                labelKey: 'potencialHedgedPlatform',
                value: 'potencialHedgedPlatform',
                path: adminPaths.potencialHedgedPlatform,
            },
            {
                labelKey: 'highFrequency',
                value: 'highFrequency',
                path: adminPaths.highFrequency,
            },
            {
                labelKey: 'profitOver500USD',
                value: 'profitOver500USD',
                path: adminPaths.profitOver500USD,
            },
            {
                labelKey: 'closedIn300Sec',
                value: 'closedIn300Sec',
                path: adminPaths.closedIn300Sec,
            },
            {
                labelKey: 'openIn600Sec',
                value: 'openIn600Sec',
                path: adminPaths.openIn600Sec,
            },
        ],
    },
    {
        labelKey: 'pnlAnalysis',
        value: 'pnlAnalysis',
        path: adminPaths.pnlAnalysis,
        subMenu: true,
        Icon: GiProfit,
        paths: [
            {
                labelKey: 'gainersAndLosers',
                value: 'gainersAndLosers',
                path: adminPaths.highGainAnalysis,
            },
            {
                labelKey: 'profitsAnalysis',
                value: 'profitsAnalysis',
                path: adminPaths.profitsAnalysis,
            },
            {
                labelKey: 'commissionAnalysis',
                value: 'commissionAnalysis',
                path: adminPaths.commissionAnalysis,
            },
            {
                labelKey: 'dailyProfitOver500USD',
                value: 'dailyProfitOver500USD',
                path: adminPaths.dailyProfitOver500USD,
            },
            {
                labelKey: 'profitableTradesClosed',
                value: 'profitableTradesClosed',
                path: adminPaths.profitableTradesClosed,
            },
            {
                labelKey: 'dailyProfitOverPerc',
                value: 'dailyProfitOverPerc',
                path: adminPaths.dailyProfitOverPerc,
            },
        ],
    },
    {
        labelKey: 'settings',
        value: 'settings',
        path: adminPaths.settings,
        subMenu: true,
        Icon: IoSettingsOutline,
        paths: [
            {
                labelKey: 'settingExcluded',
                value: 'settingExcluded',
                path: adminPaths.settingsExcluded,
            },
            {
                labelKey: 'settingRiskDefinitions',
                value: 'settingRiskDefinitions',
                path: adminPaths.settingsRiskDefinitions,
            },
            {
                labelKey: 'settingNotification',
                value: 'settingNotification',
                path: adminPaths.settingsNotification,
            },
            {
                labelKey: 'settingsRiskAdministrator',
                value: 'settingsRiskAdministrator',
                path: adminPaths.settingsRiskAdministrator,
            },
        ],
    },
    // {
    //     labelKey: 'lowGainAnalysis',
    //     value: 'lowGainAnalysis',
    //     Icon: MdAlignVerticalBottom,
    //     path: adminPaths.lowGainAnalysis,
    //     hasPermission: getPermission(
    //         'riskManagement.riskMtTradeMonitor',
    //     ),
    // },
    // {
    //     labelKey: 'hedgedPositions',
    //     value: 'hedgedPositions',
    //     Icon: TbMenuOrder,
    //     path: adminPaths.hedgedPositions,
    //     hasPermission: getPermission('riskManagement.riskMtTradeMonitor'),
    // },

    // {
    //     labelKey: 'eaOpened',
    //     value: 'eaOpened',
    //     Icon: GiTrade,
    //     path: adminPaths.eaOpened,
    //     hasPermission: getPermission('riskManagement.riskMtTradeMonitor'),
    // },
    // {
    //     labelKey: 'riskyAccount',
    //     value: 'riskyAccounts',
    //     Icon: RiSkypeLine,
    //     path: adminPaths.riskyAccounts,
    //     hasPermission: getPermission('riskManagement.riskMtTradeMonitor'),
    // },
];
