import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import {useEffect, useRef, useState} from 'react';
import {flatten, get, last} from 'lodash';

export const useSWRTemplate = (options) => {
    const {key, request, defaultValue, config = {}} = options;
    const {data, error, mutate, isValidating, isLoading, ...other} = useSWR(
        key,
        async () => {
            try {
                return await request(key);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        config,
    );
    return {
        data: data || defaultValue,
        error,
        isLoading,
        isValidating,
        mutate,
        ...other,
    };
};

export const useSWRInfiniteTemplate = (options) => {
    const {key, request, pageSize = 20, config} = options;
    const keyGenerator = (pageIndex, previousPageData) => {
        return [key, pageIndex, pageSize];
    };

    const {data, error, isValidating, mutate, size, setSize, isLoading} =
        useSWRInfinite(
            keyGenerator,
            async (arg) => {
                const [_key, _pageIndex, _pageSize] = arg;
                // sample api:
                // const result = await appStore.adminApi.getSuperProspectTransaction_(
                // 	{
                // 		pageSize: pageSize,
                // 		pageIndex: pageIndex,
                // 	}
                // );
                try {
                    return await request(_key, _pageIndex, _pageSize);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            config,
        );
    const flattenedData = data
        ? flatten([...data.map((item) => item.items)])
        : [];
    const hasMoreData = size * pageSize < get(last(data), 'totalItems', 0);
    return {
        data: data || [],
        flattenedData: flattenedData,
        error,
        isLoading,
        mutate,
        isValidating,
        size,
        setSize,
        hasMoreData,
    };
};

export const useSWRPaginationTemplate = (options = {}) => {
    const {key, request, pageSize = 100, config = {}} = options;
    const [page, setPage] = useState(1);
    const [paginationCache, setPaginationCache] = useState({
        data: [],
        totalPage: 1,
        totalItems: 0,
    });
    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        }
    }, key);
    const swr = useSWRTemplate({
        key: key ? [key, page, pageSize] : null,
        request: async (arg) => {
            const [_key, _page, _pageSize] = arg;
            try {
                const {result} = await request(_key, _page, _pageSize);
                const totalPage = Math.ceil(
                    get(result, 'totalItems', 0) / _pageSize,
                );
                const res = {
                    data: get(result, 'items'),
                    totalPage: totalPage === 0 ? 1 : totalPage,
                    totalItems: get(result, 'totalItems'),
                };
                setPaginationCache({
                    data: res.data,
                    totalPage: res.totalPage,
                    totalItems: res.totalItems,
                });
                return res;
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        defaultValue: {
            data: [],
            totalPage: 1,
            totalItems: 0,
        },
        config,
    });

    return {
        ...swr,
        page,
        setPage,
        pageSize,
        data: swr.isLoading ? paginationCache.data : swr.data.data,
        totalPage: swr.isLoading
            ? paginationCache.totalPage
            : swr.data.totalPage,
        totalItems: swr.isLoading
            ? paginationCache.totalItems
            : swr.data.totalItems,
    };
};
