import React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import theme from "./theme";
import Wizard from "./page/Wizard";
import RootRouter from "routes";
import { FormProvider } from "components/FormGenerator/ContextProvider";
import {
  formComponents,
  viewComponents,
} from "components/FormGenerator/FormGeno";
import Page from "components/Page";
import appConfig from "config/app";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <FormProvider
        formComponents={formComponents}
        viewComponents={viewComponents}
      >
        <Page ico={appConfig.ico}>
          <RootRouter />
        </Page>
      </FormProvider>
    </ChakraProvider>
  );
}

export default App;
