import {processUserProfile} from 'data/userProfile';
import client, {adminClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {isNil, split} from 'lodash';
import {get, trim} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import Excel from 'exceljs';
import {capitalize} from 'lodash';
import { api } from './common';

export const useAdminHDWalletMnemonic = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['HDWallet/mnemonic'],
        request: async () => {
            const res = await adminClient.get(['hdwallet', 'mnemonic']);
            return res?.result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
        data: swr.data?.data,
        hasMnemonic: swr.data?.result,
    };
};

export const generateMnemonic_ = async (options = {}) => {
    const {language = 'en-US'} = options;
    const res = await adminClient.post(['hdwallet', 'generate-mnemonic'], {
        language,
    });
    return res?.result?.mnemonic;
};
export const addMnemonic_ = async (options = {}) => {
    const {mnemonic, language = 'en-US'} = options;
    await adminClient.post(['hdwallet', 'mnemonic'], {
        mnemonic,
        locale: language,
    });
};
export const updateMnemonic_ = async (id, options = {}) => {
    const {mnemonic, language = 'en-US'} = options;
    await adminClient.put(['hdwallet', 'mnemonic', id], {
        mnemonic,
        locale: language,
    });
};

export const updateHDWalletConfig_ = async (id, {publicKey, privateKey}) => {
    const {result} = await adminClient.put(
        ['hdwallet', 'operator-wallet', id],
        cleanObjectNilValue({
            publicKey,
            privateKey,
        }),
    );
    return result;
};

export const updateHDWalletServiceFee_ = async (
    id,
    {serviceFee, minimalDeposit},
) => {
    const {result} = await adminClient.put(
        ['hdwallet', 'service-fee', id],
        cleanObjectNilValue({
            serviceFee: Number(serviceFee),
            minimalDeposit: Number(minimalDeposit),
        }),
    );
    return result;
};

export const useClientPlatformHDWalletStatus = (options = {}) => {
    const {request = true} = options;
    const {isUser} = useAuth()
    const swr = useSWRTemplate({
        key: request && isUser ? ['client/hdwallet'] : null,
        request: async ([_]) => {
            const {result} = await api().get(['hdwallet', 'platform']);
            return result;
        },
        defaultValue: {
            enable: false,
        },
    });
    return {
        ...swr,
    };
};

export const useClientHDWallet = (options = {}) => {
    const {request = true} = options;
    const {id} = useAuth();
    const swr = useSWRTemplate({
        key: request && id ? ['client/hdwallet', id] : null,
        request: async ([_, _id]) => {
            const {result} = await client.get(['hdwallet', 'wallet']);
            return result;
        },
        defaultValue: {
            result: false,
        },
    });
    return swr;
};

export const addUserHDWallet_ = async () => {
    const {result} = await client.post(['hdwallet', 'wallet']);
    return result;
};
export const updateUserHDWalletAssociatedAccount_ = async (userId, account) => {
    const {result} = await client.put(['hdwallet', 'wallet', userId], {
        account,
    });
    return result;
};

export const useAdminHDWalletStatus = (options = {}) => {
    const {request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['admin/hdwallet-status'] : null,
        request: async ([_]) => {
            const {result} = await adminClient.get(['hdwallet', 'status']);
            return result;
        },
        defaultValue: {
            totalHDWallets: 0,
            TRON: {
                enabledWallets: 0,
                enable: false,
                operatorWalletBalance: 0,
                platformWalletUSDTAmount: 0,
                USDTLockedSUM: 0,
                USDTHistorySUM: 0,
                USDTTransferedSUM: 0,
                lowerAlertAmount: 0,
            },
            ETH: {
                enabledWallets: 0,
                enable: false,
                operatorWalletBalance: 0,
                platformWalletUSDTAmount: 0,
                USDTLockedSUM: 0,
                USDTHistorySUM: 0,
                USDTTransferedSUM: 0,
                lowerAlertAmount: 0,
            },
        },
    });
    return {
        ...swr,
    };
};

export const useAdminClientHDWallet = (options = {}) => {
    const {user, privateKey = false} = options;
    const swr = useSWRTemplate({
        key: user ? ['admin/hdwallet', user, privateKey] : null,
        request: async ([_, _user, _privateKey]) => {
            const {result} = await adminClient.get(
                ['hdwallet', 'wallet'],
                cleanObjectNilValue({
                    user: _user,
                    privateKey: _privateKey == false ? null : true,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useWalletOperationHistory = (options = {}) => {
    const {user} = options;
    const swr = useSWRPaginationTemplate({
        key: ['admin/hdwallet-operation-history', user],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _user] = _key;
            const {result} = await adminClient.get(['hdwallet', 'history'], {
                user: _user,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            });
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => ({
                        ...item,
                        userDetail: processUserProfile(get(item, ':user')),
                    })),
                },
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const getWalletOperationHistory_ = async (options = {}) => {
    const {user} = options;
    const {result} = await adminClient.get(['hdwallet', 'history'], {
        user: user,
    });
    return result.map((item) => ({
        ...item,
        userDetail: processUserProfile(get(item, ':user')),
    }));
};

export const refetchMissingFee_ = async () => {
    const res = await adminClient.post(['hdwallet', 'refetch-missing']);
    return res;
};

export const tronTransferAllUSDToPlatform_ = async () => {
    const res = await adminClient.post([
        'hdwallet',
        'tron',
        'transfer-all-usdt-to-platform',
    ]);
    return res;
};
export const ethTransferAllUSDToPlatform_ = async () => {
    const res = await adminClient.post([
        'hdwallet',
        'eth',
        'transfer-all-usdt-to-platform',
    ]);
    return res;
};

export const downloadOpHisotry = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Operation history');
    sheet.columns = [
        {header: 'Client name', key: 'name'},
        {header: 'Network', key: 'network'},
        {header: 'Action type', key: 'type'},
        {header: 'status', key: 'status'},
        {header: 'Hash', key: 'hash'},
        {header: 'fee', key: 'fee'},
        {header: 'Transfer amount', key: 'transferAmount'},
        {header: 'Token amount', key: 'tokenAmount'},
        {header: 'From wallet', key: 'fromWallet'},
        {header: 'To wallet', key: 'toWallet'},
        {header: 'Operation wallet', key: 'opWallet'},
        {header: 'Spender wallet', key: 'spenderWallet'},
        {header: 'Allowance', key: 'allowance'},
        {header: 'Created at', key: 'createdAt'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'name':
                    row.push(get(grant, 'userDetail.name'));
                    break;
                case 'network':
                    row.push(get(grant, 'network'));
                    break;
                case 'type':
                    row.push(get(grant, 'type'));
                    break;
                case 'status':
                    row.push(get(grant, 'status'));
                    break;
                case 'hash':
                    row.push(get(grant, 'hash'));
                    break;
                case 'fee':
                    row.push(get(grant, 'fee'));
                    break;
                case 'transferAmount':
                    row.push(get(grant, 'transferAmount'));
                    break;
                case 'tokenAmount':
                    row.push(get(grant, 'tokenAmount'));
                    break;
                case 'fromWallet':
                    row.push(get(grant, 'fromWallet'));
                    break;
                case 'toWallet':
                    row.push(get(grant, 'toWallet'));
                    break;
                case 'opWallet':
                    row.push(get(grant, 'opWallet'));
                    break;
                case 'spenderWallet':
                    row.push(get(grant, 'spenderWallet'));
                    break;
                case 'allowance':
                    row.push(get(grant, 'allowance'));
                    break;
                case 'createdAt':
                    row.push(get(grant, 'createdAt'));
                    break;
            }
        }
        sheet.addRow(row);
    }
    // console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'HD Wallet operation history.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const useUserHDWallets = (options = {}) => {
    const {clientName, hasDeposit, network} = options;
    const swr = useSWRPaginationTemplate({
        key: ['admin/user-hdwallets', clientName, hasDeposit, network],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _clientName, _hasDeposit, _network] = _key;
            const {result} = await adminClient.get(['hdwallet', 'hdwallets'], cleanObjectNilValue({
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
                clientName: _clientName,
                hasDeposit: _hasDeposit,
                network: _network,
            }));
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => ({
                        ...item,
                        userDetail: processUserProfile(get(item, ':user')),
                    })),
                },
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const getUserHDWallets_ = async (options = {}) => {
    const {clientName, hasDeposit, network} = options;
    const {result} = await adminClient.get(['hdwallet', 'hdwallets'], cleanObjectNilValue({
        clientName: clientName,
        hasDeposit: hasDeposit,
        network: network,
    }));
    return result.map((item) => ({
        ...item,
        userDetail: processUserProfile(get(item, ':user')),
    }));
}

export const downloadHDWallets = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('HD Wallets');
    sheet.columns = [
        {header: 'Client name', key: 'name'},
        {header: 'Network', key: 'network'},
        {header: 'Associated account', key: 'fxAccount'},
        {header: 'USDT transfered', key: 'USDTTransfered'},
        {header: 'Wallet last balance', key: 'walletLastBalance'},
        {header: 'USDT locked', key: 'USDTLocked'},
        {header: 'Offset', key: 'derivationOffset'},
        {header: 'Wallet', key: 'wallet'},
        {header: 'Last operated at', key: 'lastOperatedAt'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'name':
                    row.push(get(grant, 'userDetail.name'));
                    break;
                case 'network':
                    row.push(get(grant, 'network'));
                    break;
                case 'fxAccount':
                    row.push(get(grant, ':fxAccount.login'));
                    break;
                case 'USDTTransfered':
                    row.push(get(grant, 'USDTTransfered'));
                    break;
                case 'walletLastBalance':
                    row.push(get(grant, 'USDTLastBalance'));
                    break;
                case 'USDTHistory':
                    row.push(get(grant, 'USDTHistory'));
                    break;
                case 'USDTLocked':
                    row.push(get(grant, 'USDTLocked'));
                    break;
                case 'derivationOffset':
                    row.push(get(grant, 'derivationOffset'));
                    break;
                case 'wallet':
                    row.push(get(grant, 'wallet'));
                    break;
                case 'lastOperatedAt':
                    row.push(get(grant, 'lastOperatedAt'));
                    break;
            }
        }
        sheet.addRow(row);
    }
    // console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'HD Wallets.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
}