// import { usePhoneCode } from 'hooks/datahooks/common';
import {split} from 'lodash';
import {isFunction} from 'lodash';
import React from 'react';
import FormWrapper from './FormWrapper';
import {HStack, Input} from '@chakra-ui/react';
import Selector from 'components/Selector';
import {usePhoneCode} from 'hooks/datahook/common';

const PhoneNumberInput = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        serverValidator,
        inputProps,
        textArea = false,
        disabled,
        readOnly,
        CustomInput,
        options = [],
    } = props;
    const {data} = usePhoneCode();
    const value = formik.values[name];
    const code = split(value, '@')?.[0] || formik.values[`${name}Code`] || '';
    const number = split(value, '@')?.[1] || formik.values[`${name}No`] || '';

    const codeValue = code;
    const numberValue = number;
    const InputComponent = CustomInput ? CustomInput : Input;
    // console.log(codeValue, numberValue)
    return (
        <FormWrapper {...props}>
            <HStack alignItems={'center'} spacing={2}>
                <Selector
                    value={codeValue}
                    onChangeValue={(v) => {
                        formik.setFieldValue(`${name}Code`, v);
                        formik.setFieldValue(name, `${v}@${number}`);
                    }}
                    isDisabled={disabled}
                    placeholder={i18n('select')}
                    flex={2}
                    options={data}
                />
                <InputComponent
                    isDisabled={disabled}
                    isReadOnly={readOnly}
                    onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(`${name}No`, value);
                        formik.setFieldValue(name, `${code}@${value}`);
                    }}
                    value={numberValue}
                    autoCapitalize="none"
                    size="sm"
                    flex={5}
                    placeholder={i18n('enter')}
                    {...inputProps}
                />
            </HStack>
        </FormWrapper>
    );
};
export default PhoneNumberInput;
// export default React.memo(PhoneNumberInput, (prev, next) => {
//     const name = prev.name;
//     return (
//         prev.formik.values[name] === next.formik.values[name] &&
//         prev.formik.errors[name] === next.formik.errors[name] &&
//         prev.formik.touched[name] === next.formik.touched[name] &&
//         prev.disabled === next.disabled &&
//         prev.readOnly === next.readOnly
//     );
// });
