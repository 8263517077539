import client, {
    adminClient,
    authAdminClient,
    authClient,
    explorerClient,
    adminClient2
} from 'helper/httpClient';
import {get, isNil} from 'lodash';
import {useAuthStore} from 'stores/authStore';
import {cleanObjectNilValue} from 'utils/clean';
import {useLocale} from './useLocale';
import {some} from 'lodash';
import {useUIStore} from 'stores/uiStore';
import {saleClient} from 'helper/httpClient';
import appConfig from 'config/app';

export const useAuth = () => {
    const state = useAuthStore((state) => state);
    const {locale} = useLocale();
    const isAdmin = get(state, 'user.roles', []).includes('ADMIN');
    const isSupport = get(state, 'user.roles', []).includes('SUPPORT');
    const isSupportManager = get(state, 'user.roles', []).includes(
        'SUPPORT_MANAGER',
    );
    const isSale = get(state, 'user.roles', []).includes('SALE');
    const isSaleManager = get(state, 'user.roles', []).includes('SALE_MANAGER');
    const isUser = get(state, 'user.roles', []).includes('USER');
    const isRiskCompliance = get(state, 'user.roles', []).includes(
        'RISK_COMPLIANCE',
    );
    const isFinance = get(state, 'user.roles', []).includes('FINANCE');
    const isITAdmin = get(state, 'user.roles', []).includes('IT_ADMIN');
    const isMarketing = get(state, 'user.roles', []).includes('MARKETING');
    const [setFirstLanguageCounter] = useUIStore((state) => [
        state.setFirstLanguageCounter,
    ]);
    return {
        ...state,
        id: get(state, 'user.id') || null,
        roles: get(state, 'user.roles') || [],
        isAdmin,
        isSupport,
        isSupportManager,
        isSale,
        isSaleManager,
        isUser,
        isRiskCompliance,
        isFinance,
        isITAdmin,
        isMarketing,
        isAdminPage: isAdmin || isSupport || isSale,
        // isUser: some(get(state, 'user.roles', []),o=>o.role==='USER'),
        isLoggedIn: Boolean(get(state, 'token')),
        login: async ({username, password, issued, code, method}) => {
            try {
                let requestClient = authAdminClient;
                if (appConfig.appPermissionControl.includes('USER')) {
                    requestClient = authClient;
                }
                const {result: auth} = await requestClient.post(
                    ['login'],
                    cleanObjectNilValue({
                        username,
                        password,
                        issued,
                        code,
                        method,
                    }),
                );

                if (auth?.issueId) {
                    return auth;
                }

                if (
                    !some(get(auth, 'user.roles', []), (rule) =>
                        appConfig.appPermissionControl.includes(rule),
                    )
                ) {
                    throw new Error('permissionDenied');
                }
                const isUser = get(auth, 'user.roles', []).includes('USER');

                if (isUser) {
                    client.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `bearer ${get(auth, 'token')}`,
                        );
                    };
                    explorerClient.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `bearer ${get(auth, 'token')}`,
                        );
                    };
                } else {
                    adminClient.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `Bearer ${get(auth, 'token')}`,
                        );
                    };
                    adminClient2.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `Bearer ${get(auth, 'token')}`,
                        );
                    };
                }
                state.setToken(get(auth, 'token'));
                state.setUser(get(auth, 'user'));
                return auth;
            } catch (error) {
                throw error;
            }
        },
        logout: (noRedirect) => {
            state.logout();
            setFirstLanguageCounter(1);
        },
        register: async ({
                             email,
                             phoneCode,
                             phoneNo,
                             type,
                             password,
                             inviterCode,
                             firstName,
                             lastName,
                             companyName,
                             region,
                         }) => {
            const {result} = await authClient.post(
                ['register'],
                cleanObjectNilValue({
                    email,
                    phoneCode,
                    phoneNo,
                    type,
                    password,
                    inviterCode,
                    locale,
                    firstname: firstName,
                    lastname: lastName,
                    companyName,
                    region,
                }),
            );
            return result;
        },
        init: () => {
            if (state.token) {
                client.onSending = (req) => {
                    req.set('Authorization', `bearer ${state.token}`);
                };
            }
        },
        verify: async ({method, issueId, code}) => {
            let requestClient = authAdminClient;
            if (appConfig.appPermissionControl.includes('USER')) {
                requestClient = authClient;
            }
            const {result: auth} = await requestClient.post(
                ['verify'],
                cleanObjectNilValue({
                    issueId,
                    code,
                    method,
                }),
            );
            const isUser = get(auth, 'user.roles', []).includes('USER');
            if (
                !some(get(auth, 'user.roles', []), (rule) =>
                    appConfig.appPermissionControl.includes(rule),
                )
            ) {
                throw new Error('permissionDenied');
            }
            if (isUser) {
                client.onSending = (req) => {
                    req.set('Authorization', `bearer ${get(auth, 'token')}`);
                };
                explorerClient.onSending = (req) => {
                    req.set('Authorization', `bearer ${get(auth, 'token')}`);
                };
            } else {
                adminClient.onSending = (req) => {
                    req.set('Authorization', `Bearer ${get(auth, 'token')}`);
                };
                adminClient2.onSending = (req) => {
                    req.set('Authorization', `Bearer ${get(auth, 'token')}`);
                };
            }
            console.log('auth', auth);
            state.setToken(get(auth, 'token'));
            state.setUser(get(auth, 'user'));
            return auth;
        },
    };
};
