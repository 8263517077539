export const paymentWaysIdChannelMap = {
    1: 'worldpay',
    2: 'finmo',
    3: 'bitwallet',
    4: 'awepay',
    5: 'fasapay',
    6: 'eft',
    7: 'web3',
    8: 'usdt',
    9: 'customerSupport',
    10: 'dragonpay',
    11: 'nacepay',
};

export const paymentWaysNameChannelMap = {
    Worldpay: 'worldpay',
    Finmo: 'finmo',
    Bitwallet: 'bitwallet',
    Awepay: 'awepay',
    Fasapay: 'fasapay',
    'Bank Transfer': 'eft',
    'Web3.0': 'web3',
    USDT: 'usdt',
    'Customer Support': 'customerSupport',
    Dragonpay: 'dragonpay',
    'NACE Pay': 'nacepay',
};

export const paymentIcons = {
    worldpay: require('assets/images/worldPay.jpg'),
    eft: require('assets/images/BankTransfer.png'),
    usdt: require('assets/images/USDT.png'),
    rapyd: require('assets/images/rapyd.jpg'),
    customerSupport: require('assets/images/CustomerSupport.png'),
    bitwallet: require('assets/images/bitwallet.jpg'),
    awepay: require('assets/images/awepay.jpg'),
    fasapay: require('assets/images/fasapay.jpg'),
    web3: require('assets/images/USDT.png'),
    finmo: require('assets/images/finmo.jpg'),
    dragonpay: require('assets/images/dragonpay.png'),
    nacepay: require('assets/images/nace.jpeg'),
};
export const paymentGatewayLimitCurrency = {
    bitwallet: ['USD', 'JPY'],
    awepay: ['IDR', 'MYR', 'THB', 'VND'],
    fasapay: ['IDR'],
    finmo: ['SGD'],
    dragonpay: ['PHP'],
};

export const paymentGatewayLimitToCurrency = {
    usdt: ['USD'],
    web3: ['USD'],
    nacepay: ['USD', 'CAD', 'EUR'],
};

export const nacepayCryptos = [
    'BTC',
    'BCH',
    'ETH',
    'LTC',
    'DOGE',
    'XRP',
    'ERC20USDC',
    'SOL',
    'BNB',
    'DASH',
    'ADA',
    'AVAX',
    'Polygon',
    'ERC20SHIB',
];

export const processPaymentGateway = (rawData) => {
    return {
        rawData,
    };
};

export const cryptoIcons = {
    USDT: require('assets/images/USDT.png'),
};

export const gatewayDisabled = ['bitwallet', 'dragonpay'];
