import {useCurrency} from 'hooks/datahook/common';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Selector from './Selector';
import {useColorModeValueKey} from 'hooks/useColors';
import CountryIcon from './CountryIcon';
import {get} from 'lodash';
import {Box, HStack, Text} from '@chakra-ui/react';

const CurrencySelector = (props) => {
    const {
        value,
        onValueChange,
        options,
        excluedCurrencies,
        showIcon = true,
        platformCurrencyOnly,
        // MT4Only,
        // MT5Only,
        ...other
    } = props;
    const {localedCurrency, isLoading, platformCurrency} = useCurrency();
    const {t} = useTranslation('app');
    const baseCurrencies = (
        platformCurrencyOnly ? platformCurrency : localedCurrency
    )
        // .filter((currency) => (MT4Only ? currency.isMT4Support : true))
        // .filter((currency) => (MT5Only ? currency.isMT5Support : true));
        
    const displayCurrencies = excluedCurrencies
        ? baseCurrencies.filter(
              (country) => !excluedCurrencies.includes(country.value),
          )
        : baseCurrencies;
    const grayColor = useColorModeValueKey('gray');
    const customRenderItem = (option) => {
        return (
            <HStack alignItems={'flex-start'} spacing={2}>
                <Box mt={1}>
                    <CountryIcon size={20} country={get(option, 'country')} />
                </Box>
                <Text
                    fontSize={'sm'}
                    color={option.disabled ? grayColor : undefined}
                >
                    {option.label}
                </Text>
            </HStack>
        );
    };
    const customRenderInputLeftElement = (option) => {
        if (option) {
            return <CountryIcon size={20} country={get(option, 'country')} />;
        } else {
            return null;
        }
    };
    return (
        <Selector
            value={value}
            onChangeValue={onValueChange}
            options={options || displayCurrencies}
            customRenderItem={showIcon ? customRenderItem : null}
            customRenderInputLeftElement={
                showIcon ? customRenderInputLeftElement : null
            }
            isLoading={isLoading}
            {...other}
        />
    );
};

export default CurrencySelector;
