import {
    Avatar,
    Button,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import {useAdminProfile, useProfile} from 'hooks/datahook/auth';
import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey} from 'hooks/useColors';
import {get} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {HiLogout} from 'react-icons/hi';
import {
    HiCog8Tooth,
    HiShieldCheck,
    HiIdentification,
    HiPower,
} from 'react-icons/hi2';
import { IoShield } from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const ProfileBtn = (props) => {
    const {isUser, logout} = useAuth();
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const {
        data: profile,
        kycRequired,
        kycPending,
        isCompany,
    } = (isUser ? useProfile : useAdminProfile)();
    // const isMobile = useBreakpointValue({
    //     base: true,
    //     md: false,
    // });

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Avatar menu"
                rounded={'full'}
                overflow={'hidden'}
                variant={'ghost'}
            >
                <Avatar
                    name={get(profile, 'name')}
                    src={get(profile, 'avatar')}
                    borderRadius={'full'}
                    height={'39px'}
                    width={'39px'}
                    objectFit={'cover'}
                />
            </MenuButton>
            <MenuList>
                <MenuItem
                    onClick={() =>
                        navigate(isUser ? paths.profile : adminPaths.profile)
                    }
                    // key={item.value}
                    // icon={<Avatar size='xs' src={get(profile,'avatar')}/>}
                >
                    <HStack>
                        <Avatar
                            size="sm"
                            src={get(profile, 'avatar')}
                            name={get(profile, 'name')}
                        />
                        <Stack spacing={0}>
                            <Text fontWeight={'600'}>
                                {get(profile, 'name')}
                            </Text>
                            <Text
                                fontSize={'2xs'}
                                color={useColorModeValueKey('gray')}
                            >
                                {get(profile, 'email')}
                            </Text>
                        </Stack>
                    </HStack>
                </MenuItem>
                <MenuDivider />
                {isUser && (
                    <MenuItem
                        onClick={() =>
                            kycRequired || kycPending
                                ? navigate(isCompany ? paths.kyb : paths.kyc)
                                : navigate(paths.profileKycReview)
                        }
                        icon={<Icon as={HiIdentification} boxSize={5} />}
                    >
                        {t(isCompany ? 'kyb' : 'kyc')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() =>
                        navigate(
                            isUser ? paths.preference : adminPaths.preference,
                        )
                    }
                    icon={<Icon as={HiCog8Tooth} boxSize={5} />}
                >
                    {t('preference')}
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        navigate(isUser ? paths.security : adminPaths.security)
                    }
                    icon={<Icon as={IoShield} boxSize={5} />}
                >
                    {t('security')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                    onClick={() => logout()}
                    icon={<Icon as={HiPower} boxSize={5} />}
                >
                    {t('logout')}
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default ProfileBtn;
