import {extendTheme} from '@chakra-ui/react';
import '@fontsource/inter/800.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/100.css';
import Input from './StyledConfig/Input';
import Button from './StyledConfig/Button';
import {FormLabel} from './StyledConfig/Form';
import Menu from './StyledConfig/Menu';
import {TextareaTheme} from './StyledConfig/Textarea';
import PinInputTheme from './StyledConfig/PinInput';
import {
    colorByMode,
    convertColorToCssVar,
    flattenColorPalette,
    generatePalette,
} from './themeHelper';
import {popoverTheme} from './StyledConfig/Popover';
import {switchTheme} from './StyledConfig/Switch';
import {checkboxTheme} from './StyledConfig/Checkbox';
import {tagTheme} from './StyledConfig/Tag';

export const HEADER_HEIGHT = '60px';

export const designSystemColors = {
    light: {
        primary: 'primary.500',
        bg: 'bg.100',
        brighterBg: 'bg.50',
        navBg: 'bg.50',
        sideBarBg: 'bg.50',
        sideBarText: 'gray.800',
        text: 'gray.800',
        secondaryText: 'gray.700',
        active: 'primary.500',
        gray: 'gray.400',
        border: 'gray.200',
        gold: 'gold.500',
        copper: 'warning.300',
        silver: 'gray.200',

        success: 'success.500',
        warning: 'warning.400',
        pending: 'pending.400',
        error: 'error.500',
        tag: 'gray.300',

        menuHover: 'bg.100',
        menuActive: 'bg.200',

        stockRed: 'error.600',
        stockGreen: 'success.500',

        Live: 'success.500',
        Rebate: 'warning.400',
        Hidden: 'error.500',
        tip: 'warning.200'
    },
    dark: {
        primary: 'primary.500',
        bg: 'bg.800',
        brighterBg: 'bg.700',
        navBg: 'bg.700',
        sideBarBg: 'bg.800',
        sideBarText: 'gray.200',
        text: 'gray.50',
        secondaryText: 'gray.200',
        active: 'primary.500',
        gray: 'gray.200',
        border: 'gray.500',
        gold: 'gold.500',
        copper: 'warning.300',
        silver: 'gray.200',

        success: 'success.500',
        warning: 'warning.400',
        pending: 'pending.400',
        error: 'error.500',
        tag: 'gray.400',

        menuHover: 'bg.600',
        menuActive: 'bg.500',

        stockRed: 'error.600',
        stockGreen: 'success.500',

        Live: 'success.500',
        Rebate: 'warning.400',
        Hidden: 'error.500',
        tip: 'warning.200'
    },
};

//default theme for dev
const lightBg = '#ffffff';
const darkBg = '#131316';
const gray = {
    50: '#f6f6f7',
    100: '#e1e2e6',
    200: '#c3c5cc',
    300: '#9ea0aa',
    400: '#797b88',
    500: '#5f606d',
    600: '#4b4c56',
    700: '#3e3f47',
    800: '#34353b',
    900: '#2e2e33',
    950: '#131316',
};
const error = {
    50: '#fff1f1',
    100: '#ffe0e0',
    200: '#ffc7c7',
    300: '#ffa0a0',
    400: '#ff6a6a',
    450: '#EF6666',
    500: '#f94e4e',
    600: '#e61c1c',
    700: '#c21313',
    800: '#a01414',
    900: '#841818',
    950: '#480707',
};
const success = {
    50: '#ecfdf5',
    100: '#d1fae5',
    200: '#a7f3d0',
    300: '#6ee7b7',
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
    700: '#047857',
    800: '#065f46',
    900: '#064e3b',
};
const primary = {
    50: '#F2FBFD',
    100: '#E1F5F9',
    200: '#BEEAF3',
    300: '#93DCEB',
    400: '#64CDE2',
    500: '#2CBBD8',
    600: '#229FB8',
    700: '#1D8A9F',
    800: '#197385',
    900: '#12525F',
    950: '#0E3F49',
};
const pending = {
    50: '#F2FBFD',
    100: '#E1F5F9',
    200: '#BEEAF3',
    300: '#93DCEB',
    400: '#64CDE2',
    500: '#2CBBD8',
    600: '#229FB8',
    700: '#1D8A9F',
    800: '#197385',
    900: '#12525F',
    950: '#0E3F49',
};

// const primary = {
//     50: '#eff9ff',
//     100: '#def1ff',
//     200: '#b6e5ff',
//     300: '#76d1ff',
//     400: '#2dbcff',
//     500: '#02a4f5',
//     600: '#0082d2',
//     700: '#0068aa',
//     800: '#005f98',
//     900: '#074873',
//     950: '#042e4d',
// };
// const primary = {
//     50: '#fff9ed',
//     100: '#fff1d6',
//     200: '#fde0ab',
//     300: '#fbc876',
//     400: '#f9a63e',
//     500: '#f68b19',
//     600: '#f07612',
//     700: '#c0550e',
//     800: '#984314',
//     900: '#7b3913',
//     950: '#421a08',
// };
const warning = {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#f68b19',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
    950: '#421a08',
};

const lightGray = {
    '50': '#f5f5f5',
    '100': '#f5f5f5',
    '200': '#e6e6e6',
    '300': '#cccccc',
    '400': '#adadad',
    '500': '#969696',
    '600': '#858585',
    '700': '#757575',
    '800': '#6e6e6e',
    '900': '#737373',
    '950': '#808080',
}

const gold = {
    '50': '#fffee7',
    '100': '#fffec1',
    '200': '#fff886',
    '300': '#ffec41',
    '400': '#ffdb0d',
    '500': '#ffcc00',
    '600': '#d19500',
    '700': '#a66a02',
    '800': '#89530a',
    '900': '#74430f',
    '950': '#442304',
};

const bgPalette = generatePalette(lightBg, darkBg);

export const designSystemPalette = {
    gray,
    light: {
        bg: bgPalette,
        primary,
        gray,
        error,
        success,
        warning,
        pending,
        lightGray,
        gold
    },
    dark: {
        bg: bgPalette,
        primary,
        gray,
        error,
        success,
        warning,
        pending,
        lightGray,
        gold
    },
};

export default extendTheme({
    components: {
        Input,
        Button,
        FormLabel,
        Menu,
        Textarea: TextareaTheme,
        PinInput: PinInputTheme,
        Popover: popoverTheme,
        Switch: switchTheme,
        Checkbox: checkboxTheme,
        Tag: tagTheme,
    },
    shadows: {
        outline: `0 0 0 2px ${convertColorToCssVar('light.primary.500')}`,
        sideBar: '15px 1px 34px 0px rgba(0,0,0,0.05);',
    },
    colors: designSystemPalette,
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
        disableTransitionOnChange: false,
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
        mono: `'Inter', sans-serif`,
    },
    styles: {
        global: (props) => {
            return {
                body: {
                    color: colorByMode('text', props.colorMode),
                    bg: colorByMode('bg', props.colorMode),
                },
            };
        },
    },
});
