// import CRMLayout from 'layouts/CRMLayout';
import React, {lazy, Suspense} from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    useLocation,
    useRoutes,
} from 'react-router-dom';
import {
    paths,
    PATH_AFTER_LOGIN,
    PATH_TO_REDIRECTOR,
    PATH_AFTER_ADMIN_LOGIN,
    adminPaths,
    authPaths,
    devPaths,
} from './path';
import GuestLayout from 'layouts/GuestLayout';
import GuestGuard from 'layouts/guard/GuestGuard';
import LoadingScreen from 'page/LoadingScreen';
import AppLayout from 'layouts/AppLayout';
import {useAuth} from 'hooks/useAuth';
import AdminGuard from 'layouts/guard/AdminGuard';
import ProfileLayout from 'layouts/ProfileLayout';
import {get} from 'lodash';
import ApprovalsLayout from 'layouts/ApprovalsLayout';
import AdminPermissionGuard from 'layouts/guard/AdminPermissionGuard';
import RiskReportsLayout from 'layouts/RiskReportsLayout';
import SettingExcludeLayout from 'layouts/SettingExcludeLayout';
import {useUIStore} from 'stores/uiStore';
import {SWRConfig} from 'swr';
import {RNConfig} from '../swr/config';
import client, {adminClient} from 'helper/httpClient';
import {useAuthStore} from 'stores/authStore';

const getAuthRoute = () => {
    return [
        {
            path: 'auth',
            element: <GuestLayout />,
            children: [
                {
                    element: <Navigate to={authPaths.login} replace />,
                    index: true,
                },
                {
                    path: 'login',
                    element: <Login />,
                },
                {
                    path: 'sign-up',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                {path: 'logout', element: <Logout />},
                {path: 'sign-up-verify', element: <SignUpVerify />},
                {path: 'sign-up-link-verify', element: <SignUpLinkVerify />},
                {path: 'forgot-password', element: <ForgotPassword />},
                {path: 'reset-password', element: <ResetPassword />},
            ],
        },
    ];
};

export const getEdgeCaseRoute = () => {
    return [
        {
            path: '/',
            element: <Navigate to={'/redirector'} replace />,
        },
        {
            path: '/redirector',
            element: <Redirector replace />,
        },
        {
            path: '*',
            element: <GuestLayout />,
            children: [
                {path: 'maintenance', element: <Maintenance />},
                {path: '404', element: <Page404 />},
                {path: '403', element: <Page403 />},
                {path: '*', element: <Navigate to="/404" replace />},
            ],
        },
    ];
};

export const getAdminRoute = () => {
    return [
        {
            path: 'admin',
            element: (
                <AdminGuard>
                    <AppLayout />
                </AdminGuard>
            ),
            children: [
                {
                    element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />,
                    index: true,
                },
                {
                    path: 'dashboard',
                    element: <AdminDashboard />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('dashboard'),
                            path: adminPaths.dashboard,
                        }),
                    },
                },
                {
                    path: 'clients-accounts',
                    children: [
                        {
                            element: <AdminClientsAccounts />,
                            index: true,
                        },
                        {
                            path: 'mt-account',
                            element: <AdminClientsAccounts />,
                        },
                        {
                            path: 'monitor-account',
                            element: <AccountsMonitor />,
                        },
                        {
                            path: 'email-monitor',
                            element: <EmailMonitor />
                        },
                        {
                            path: 'account-assessment',
                            element: <AccountAssessment />
                        }
                    ],
                },
                {
                    path: 'trade-analysis',
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.riskMtTradeMonitor}
                                    replace={true}
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'absolute-hedged',
                            element: <HedgedPositions />,
                        },
                        {
                            path: 'across-hedged',
                            element: <AcrossAccountHedged />,
                        },
                        {
                            path: 'platform-hedged',
                            element: <AcrossPlatformPositions />,
                        },
                        {
                            path: 'mt-monitor',
                            element: <RiskMtMonitorTotal />,
                        },
                        {
                            path: 'closed-monitor',
                            element: <RiskClosedMtMonitorTotal />,
                        },
                        {
                            path: 'high-frequency',
                            element: <HighFrequencyAccounts />,
                        },
                        {
                            path: 'profit-over-currency',
                            element: <ProfitOverCurrency />,
                        },
                        {
                            path: 'closed-in-second',
                            element: <ClosedInSencond />,
                        },
                        {
                            path: 'open-in-second',
                            element: <OpenInSencond />,
                        },
                    ],
                },
                {
                    path: 'pnl-analysis',
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.highGainAnalysis}
                                    replace={true}
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'high-gain',
                            element: <HighGainAccountAnalysis />,
                        },
                        {
                            path: 'profits-analysis',
                            element: <ProfitsAnalysis />,
                        },
                        {
                            path: 'commission-analysis',
                            element: <CommissionAnalysis />,
                        },
                        {
                            path: 'daily-profit-over-currency',
                            element: <DailyProfitOverCurrency />,
                        },
                        {
                            path: 'profitable-trades-closed',
                            element: <ProfitableTradesClosedInSencond />,
                        },
                        {
                            path: 'daily-profit-over-perc',
                            element: <DailyProfitOverPerc />,
                        },
                    ],
                },

                {
                    path: 'risk-management',
                    element: (
                        <AdminPermissionGuard
                            permissionKey={'riskManagement'}
                        />
                    ),
                    children: [
                        {
                            path: 'risk-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'riskManagement.riskReports'}
                                >
                                    <RiskReportsLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.salesReports}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'sales-reports',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.riskReports.salesReports'
                                            }
                                        >
                                            <AdminSalesReports />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('salesReports'),
                                            path: adminPaths.salesReports,
                                        }),
                                    },
                                },
                            ],
                        },

                        {
                            path: 'profits-analysis',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.profitsAnalysis'
                                    }
                                >
                                    <ProfitsAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'commission-analysis',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.commissionAnalysis'
                                    }
                                >
                                    <CommissionAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },

                        {
                            path: 'high-gain',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <HighGainAccountAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'low-gain',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <LowGainAccountAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'hedged-positions',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <HedgedPositions />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'high-frequency',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <HighFrequencyAccounts />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'ea-opened',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <EAOpenedTrades />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'risky-account',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <RiskyAccounts />
                                </AdminPermissionGuard>
                            ),
                        },
                    ],
                },
                {
                    path: 'profile',
                    element: <ProfileLayout />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('profile'),
                            path: adminPaths.profile,
                        }),
                    },
                    children: [
                        {
                            element: <Profile />,
                            index: true,
                        },
                        {
                            path: 'preference',
                            element: <Preference />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('preference'),
                                    path: adminPaths.preference,
                                }),
                            },
                        },
                        {
                            path: 'security',
                            element: <Security />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('security'),
                                    path: adminPaths.security,
                                }),
                            },
                        },
                        {
                            path: 'notification',
                            element: <NotificationSetting />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('notification'),
                                    path: adminPaths.notificationSetting,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'settings',
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.settingsExcluded}
                                    replace
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'setting-excluded',
                            element: <SettingExcludeLayout />,
                            children: [
                                {
                                    element: <Navigate to={adminPaths.settingsExcludedGroups} replace />,
                                    index: true
                                },
                                {
                                    path: 'groups',
                                    element: <SettingExcludedGroups />,
                                },
                                {
                                    path: 'accounts',
                                    element: <SettingExcludedAccount />,
                                },
                            ],
                        },
                        {
                            path: 'setting-risk-definitions',
                            element: <SettingDefinitions />,
                        },
                        {
                            path: 'setting-notification',
                            element: <SettingNotification />,
                        },
                        {
                            path: 'risk-administrator',
                            element: <SettingRiskAdministrator />,
                        },
                    ],
                },
                {
                    path: 'notification',
                    element: (
                        <AdminPermissionGuard permissionKey={'notification'}>
                            <AdminNotification />
                        </AdminPermissionGuard>
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('notification'),
                            path: adminPaths.notification,
                        }),
                    },
                },
                {path: 'logout', element: <Logout />},
                {path: 'settings', element: <Settings />},
                {path: '404', element: <Page404 />},
                {path: '403', element: <Page403 />},
                {path: '*', element: <Navigate to="/crm/404" replace />},
            ],
        },
    ];
};

export const RootRouter = () => {
    const authRoute = getAuthRoute();
    const adminRoute = getAdminRoute();
    const edgeCaseRoute = getEdgeCaseRoute();
    const authLogout = useAuthStore((state) => state.logout);
    const uiState = useUIStore((state) => state);
    const router = createBrowserRouter(
        [...authRoute, ...edgeCaseRoute, ...adminRoute],
        // {basename: '/client'},
    );

    return (
        <SWRConfig value={{...RNConfig({logout: authLogout, uiState})}}>
            <RouterProvider router={router} />
        </SWRConfig>
    );
    // return <RouterProvider router={router} />;
};

export default RootRouter;

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
};

const Page404 = Loadable(lazy(() => import('page/Page404')));
const Page403 = Loadable(lazy(() => import('page/Page403')));

const Login = Loadable(lazy(() => import('page/auth/Login')));
const Redirector = Loadable(lazy(() => import('page/Redirector')));
const Maintenance = Loadable(lazy(() => import('page/Maintenance')));

// const Register = Loadable(lazy(() => import('pages/Register')));
// const SignUpVerify = Loadable(lazy(() => import('pages/SignUpVerify')));
const ForgotPassword = Loadable(lazy(() => import('page/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('page/auth/ResetPassword')));
const Register = Loadable(lazy(() => import('page/auth/Register')));
const SignUpVerify = Loadable(lazy(() => import('page/auth/SignUpVerify')));
const SignUpLinkVerify = Loadable(
    lazy(() => import('page/auth/SignUpLinkVerify')),
);

//app pages
const Settings = Loadable(lazy(() => import('page/app/Settings')));
const Profile = Loadable(lazy(() => import('page/app/Profile/Profile')));

const NotificationSetting = Loadable(
    lazy(() => import('page/app/Profile/Notifications')),
);

const Preference = Loadable(lazy(() => import('page/app/Profile/Preference')));
const Security = Loadable(lazy(() => import('page/app/Profile/Security')));

const Logout = Loadable(lazy(() => import('page/Logout')));

//admin pages
const AdminDashboard = Loadable(lazy(() => import('page/admin/Dashboard')));

//Admin risk management

const AdminSalesReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/RiskReports/SalesReports')),
);

const RiskMtMonitorTotal = Loadable(
    lazy(() => import('page/admin/RiskManagement/RiskMtMonitor/total')),
);
const RiskClosedMtMonitorTotal = Loadable(
    lazy(() => import('page/admin/RiskManagement/ClosedMtmonitor/total')),
);

const HighGainAccountAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/HighGainAccountAnalysis')),
);

const LowGainAccountAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/LowGainAccountAnalysis')),
);

const HedgedPositions = Loadable(
    lazy(() => import('page/admin/RiskManagement/HedgedPositions')),
);

const AcrossAccountHedged = Loadable(
    lazy(() => import('page/admin/RiskManagement/AcrossAccountHedged')),
);

const AcrossPlatformPositions = Loadable(
    lazy(() => import('page/admin/RiskManagement/AcrossPlatformPositions')),
);

const HighFrequencyAccounts = Loadable(
    lazy(() => import('page/admin/RiskManagement/HighFrequencyAccounts')),
);

const EAOpenedTrades = Loadable(
    lazy(() => import('page/admin/RiskManagement/EAOpenedTrades')),
);

const ProfitsAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/ProfitsAnalysis')),
);
const CommissionAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/CommissionAnalysis')),
);

const AdminClientsAccounts = Loadable(
    lazy(() => import('page/admin/Clients/Accounts')),
);

const AccountsMonitor = Loadable(
    lazy(() => import('page/admin/RiskManagement/AccountsMonitor')),
);

const EmailMonitor = Loadable(
    lazy(() => import('page/admin/RiskManagement/EmailMonitor')),
);

const AccountAssessment = Loadable(
    lazy(() => import('page/admin/RiskManagement/AccountAssessment')),
);

const RiskyAccounts = Loadable(
    lazy(() => import('page/admin/RiskManagement/RiskyAccounts')),
);

const AdminNotification = Loadable(
    lazy(() => import('page/admin/Notification')),
);

const ProfitOverCurrency = Loadable(
    lazy(() => import('page/admin/RiskManagement/ProfitOverCurrency')),
);

const ClosedInSencond = Loadable(
    lazy(() => import('page/admin/RiskManagement/ClosedInSencond')),
);

const OpenInSencond = Loadable(
    lazy(() => import('page/admin/RiskManagement/OpenInSencond')),
);

const DailyProfitOverCurrency = Loadable(
    lazy(() => import('page/admin/RiskManagement/DailyProfitOverCurrency')),
);

const ProfitableTradesClosedInSencond = Loadable(
    lazy(() =>
        import('page/admin/RiskManagement/ProfitableTradesClosedInSencond'),
    ),
);

const DailyProfitOverPerc = Loadable(
    lazy(() => import('page/admin/RiskManagement/DailyProfitOverPerc')),
);

const SettingExcludedGroups = Loadable(
    lazy(() => import('page/admin/settings/SettingExcludedGroups')),
);

const SettingExcludedAccount = Loadable(
    lazy(() => import('page/admin/settings/SettingExcludedAccount')),
);

const SettingDefinitions = Loadable(
    lazy(() => import('page/admin/settings/SettingDefinitions')),
);

const SettingNotification = Loadable(
    lazy(() => import('page/admin/settings/SettingNotification')),
);

const SettingRiskAdministrator = Loadable(
    lazy(() => import('page/admin/settings/SettingRiskAdministrator')),
);
