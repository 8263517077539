const endpointBase = process.env.REACT_APP_HOST_API_KEY;
const isProduction = process.env.REACT_APP_PRODUCTION == 'true';

// export const isOX = 
//     !isProduction||
//     window.location.hostname === 'ox-crm-admin.gen-x.dev' ||
//     window.location.hostname === 'ox-crm-client.gen-x.dev';
export const isDev = 
    window.location.hostname == 'localhost' ||
    window.location.hostname == 'bull-crm-admin.gen-x.dev' ||
    window.location.hostname == 'bull-crm-client.gen-x.dev' ||
    process.env.REACT_APP_IS_DEV;

import LogoIcon from 'assets/images/OXLogo3.jpg';
import BullKingLogoShortIcon from 'assets/images/Logo-short.png';
import BullKingLogoLongIcon from 'assets/images/Logo-long2.png';
import FoxIcon from 'assets/images/faviconFox.svg';
import FoxLongIcon from 'assets/images/FoxLong.jpg';

const appConfig = {
    name: '',
    displayName: '',
    ico: isDev ? 'faviconFox.svg' : 'favicon.ico',
    logo: isDev ? FoxLongIcon : LogoIcon,
    logoShort: isDev ? FoxIcon : LogoIcon,
    apiEndpoint: `${endpointBase}/client/v1`,
    apiEndpoint2: `${endpointBase}/admin/v2`,
    adminApiEndpoint: `${endpointBase}/v1`,
    adminApiEndpoint2: `${endpointBase}/v2`,
    saleApiEndpoint: `${endpointBase}/sale/v1`,
    authApiEndpoint: `${endpointBase}/v1/auth`,
    adminAuthApiEndpoint: `${endpointBase}/v1/auth`,
    explorerApiEndpoint: `${endpointBase}/explorer/v1`,

    storePrefix: process.env.REACT_APP_STORE_PREFIX,
    appPermissionControl: process.env.REACT_APP_PERMISSION_CONTROL
        ? process.env.REACT_APP_PERMISSION_CONTROL.split(',')
        : ['USER', 'ADMIN', 'SALE'],
};
export default appConfig;
