import create from 'zustand';
import {persist} from 'zustand/middleware';
import appConfig from '../config/app';
import client, { adminClient, adminClient2 } from 'helper/httpClient';

export const useAuthStore = create(
    persist(
        (set, get) => ({
            token: null,
            expiredAt: null,
            user: null,
            setToken: (value) => set(() => ({token: value})),
            setExpiredat: (value) => set(() => ({expiredAt: value})),
            setUser: (value) => set(() => ({user: value})),
            logout: () => {
                set(() => ({token: null, expiredAt: null, user: null}));
                delete client.onSending;
                delete adminClient.onSending;
                delete adminClient2.onSending;
            },
        }),
        {name: `${appConfig.storePrefix}-authStore`},
    ),
);
