import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useColorModeValueKey} from 'hooks/useColors';
import {useDebounce, useMeasure} from 'react-use';
import {get, isEmpty, isNil} from 'lodash';
import {useSearchBankDetail} from 'hooks/datahook/withdrawal';
import {
    Center,
    Input,
    InputGroup,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverContent,
    Spinner,
    Stack,
    Text,
} from '@chakra-ui/react';

const BankSearchInput = (props) => {
    const {
        bankSearchType,
        onChangeText,
        value,
        onSelectBankInfo,
        flex,
        isDisabled,
        CustomInput,
        ...other
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [debounceText, setDebounceText] = useState('');
    const {t} = useTranslation('app');
    const {t: countriesT} = useTranslation('countries');
    const grayColor = useColorModeValueKey('gray');
    const [inputLayoutRef, {width}] = useMeasure();

    useDebounce(
        () => {
            setDebounceText(value);
        },
        500,
        [value],
    );
    const {data, error, isLoading} = useSearchBankDetail({
        type: bankSearchType,
        value: debounceText,
    });
    const CInput = CustomInput || Input;
    return (
        <Stack flex={flex}>
            <Popover
                placement="bottom-start"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                isLazy
                closeOnBlur={false}
                autoFocus={false}
            >
                <PopoverAnchor>
                    <InputGroup size={'sm'} ref={inputLayoutRef}>
                        <CInput
                            value={value}
                            onChange={(e) => {
                                onChangeText(e.target.value);
                                if (e.target.value?.length >= 3) {
                                    setIsOpen(true);
                                }
                            }}
                            variant={'outline'}
                            placeholder={t('enter')}
                            flex={1}
                            isDisabled={isDisabled}
                            onFocus={() => {
                                if (value?.length >= 2) {
                                    setIsOpen(true);
                                }
                            }}
                            onBlur={() => {
                                setIsOpen(false);
                                if (data && !error && !isLoading) {
                                    onSelectBankInfo(data);
                                }
                            }}
                            {...other}
                        />
                    </InputGroup>
                </PopoverAnchor>
                <PopoverContent
                    width={width}
                    overflow={'hidden'}
                    boxShadow={'lg'}
                    borderColor={useColorModeValueKey('border')}
                >
                    <PopoverBody
                        px={0}
                        py={0}
                        bg={useColorModeValueKey('brighterBg')}
                        transition={'all 200ms linear'}
                    >
                        <Stack spacing={0}>
                            {isLoading || (debounceText?.length || 0) <= 2 ? (
                                <Center py={2}>
                                    <Spinner />
                                </Center>
                            ) : error || isNil(data)||isEmpty(data) ? (
                                <Center>
                                    <Text px={3} py={2} color={grayColor}>
                                        {t('bankInfoNotFound')}
                                    </Text>
                                </Center>
                            ) : (
                                <Stack
                                    onClick={() => onSelectBankInfo(data)}
                                    spacing={1}
                                    cursor={'pointer'}
                                    px={3}
                                    py={2}
                                >
                                    <Text>{get(data, 'bankName')}</Text>
                                    <Text fontSize={'xs'} color={grayColor}>
                                        {get(data, 'bankAddress')}
                                    </Text>
                                    <Text fontSize={'xs'} color={grayColor}>
                                        {countriesT(get(data, 'country'))},
                                        {get(data, 'bankPostCode')}
                                    </Text>
                                </Stack>
                            )}
                        </Stack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Stack>
    );
};

export default BankSearchInput;
