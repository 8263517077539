import {useSWRTemplate} from 'hooks/dataHooksTemplate';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {
    processWithdrawalAccount,
    processBankWithdrawalMethods,
} from 'data/withdrawalAccount';
import {useAuth} from 'hooks/useAuth';

export const useWithdrawalAccountList = (options = {}) => {
    const {status = null} = options;
    const swr = useSWRTemplate({
        key: ['funding/withdrawal-account-list',status],
        request: async ([_,_status]) => {
            const {result} = await api().get(['funding', 'withdrawal-account'],
                cleanObjectNilValue({
                    // status: _status
                }));
            return result.map(processWithdrawalAccount)
                  .filter((item) => (_status ? item.status == 'activated' : true));
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useWithdrawalAccounDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['funding/withdrawal-account-detail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get([
                'funding',
                'withdrawal-account',
                _id,
            ]);
            return processWithdrawalAccount(result);
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const editDisplayName_ = async (accountId, data) => {
    const res = await api().put(
        ['funding', 'withdrawal-account', accountId],
        data,
    );
    return res;
};

export const useWithdrawalSupportMethod = (options = {}) => {
    const {currency} = options;
    const swr = useSWRTemplate({
        key: currency ? ['withdrawalSupportMethod', currency] : null,
        request: async ([_, _currency]) => {
            const {result} = await api().get(
                ['platform', 'withdrawal-support-method'],
                {
                    currency: _currency,
                },
            );

            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useBankWithdrawalMethods = (options = {}) => {
    const {currency} = options;
    const {isAdmin} = useAuth();
    const swr = useSWRTemplate({
        key: currency ? ['bank-withdrawal-methods', currency] : null,
        request: async ([_, _currency]) => {
            const {result} = await api().get(
                [isAdmin ? 'commons' : 'platform', 'withdrawal-support-method'],
                {
                    currency: _currency,
                },
            );
            return result.map(processBankWithdrawalMethods);

            // return [
            //     {
            //         id: 1,
            //         name: 'BSB',
            //         rule: {
            //             bsbCode: '/d+/',
            //             accountNumber: '/d+/',
            //             accountHolderName: '/d+/',
            //         },
            //     },
            //     {
            //         id: 2,
            //         name: 'SWIFT',
            //         rule: {
            //             bicSwift: '/d+/',
            //             accountNumber: '/d+/',
            //             accountHolderName: '/d+/',
            //         },
            //     },
            // ];
        },
        defaultValue: [],
    });
    return swr;
};

export const useSearchBankDetail = (options = {}) => {
    const {type, value} = options;
    const swr = useSWRTemplate({
        key: type && value ? ['search-bank-detail', type, value] : null,
        request: async ([_, _type, _value]) => {
            const {result} = await api().get(['bank-detail'], {
                type: _type,
                value: _value,
            });
            return result;
            // return {
            //     bankName: 'Bank name',
            //     bankAddress: 'Bank address', //line1
            //     country: 'US',
            //     bankPostCode: '12345678910',
            //     stateISO: 'New York State',
            //     bankCity: 'New York',
            // };
        },
        defaultValue: {},
        confirg: {
            errorRetryCount: 0,
        },
    });
    return {
        ...swr,
    };
};

export const useWithdrawalFee = (options = {}) => {
    const {amount, fromFxAccountId, toWithdrawalAccountId} = options;
    return useSWRTemplate({
        key:
            amount && fromFxAccountId && toWithdrawalAccountId
                ? [
                      'useWithdrawalFee',
                      amount,
                      fromFxAccountId,
                      toWithdrawalAccountId,
                  ]
                : null,
        request: async ([
            _,
            _amount,
            _fromFxAccountId,
            _toWithdrawalAccountId,
        ]) => {
            const {result} = await api().post(
                ['withdrawal', 'fee'],
                cleanObjectNilValue({
                    amount: _amount,
                    accountId: _fromFxAccountId,
                    withdrawalAccountId: _toWithdrawalAccountId,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
};

export const addWithdrawalAccount_ = async (data) => {
    const res = await api().post(
        ['funding', 'withdrawal-currency-account'],
        cleanObjectNilValue(data),
    );
    return res;
};

export const createWithdrawal_ = async (data) => {
    const res = await api().post(['fx', 'withdraw'], cleanObjectNilValue(data));
    return res;
};

export const addWithdrawalCrypto_ = async (data) => {
    const res = await api().post(
        ['funding', 'withdrawal-crypto-account'],
        cleanObjectNilValue(data),
    );
    return res;
};
