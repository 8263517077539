import {split} from 'lodash';
import {last} from 'lodash';
import generalClient from 'superagent';
import {useTranslation} from 'react-i18next';
import {useSWRTemplate} from 'hooks/dataHooksTemplate';
import {sortBy} from 'lodash';
import {get} from 'lodash';
import client, {adminClient, saleClient} from 'helper/httpClient';
import {processCurrency} from 'data/curreny';
import {cleanObjectNilValue} from 'utils/clean';

export const api = () => {
    const store = localStorage.getItem(
        `${process.env.REACT_APP_STORE_PREFIX}-authStore`,
    );
    if (store) {
        const state = JSON.parse(store);
        // const isAdminUser = (state?.state?.user?.roles || []).includes('ADMIN');
        const isUser = (state?.state?.user?.roles || []).includes('USER');
        // const isSALE = (state?.state?.user?.roles || []).includes('SALE');
        return isUser ? client : adminClient;
    } else {
        return client;
    }
};

export const getCurrentUserType = () => {
    const store = localStorage.getItem(
        `${process.env.REACT_APP_STORE_PREFIX}-authStore`,
    );
    if (store) {
        const state = JSON.parse(store);
        const isAdmin = (state?.state?.user.roles || []).includes('ADMIN');
        const isSupport = (state?.state?.user.roles || []).includes('DEALER');
        const isUser = (state?.state?.user.roles || []).includes('USER');
        const isSale = (state?.state?.user.roles || []).includes('SALE');
        return {
            isAdmin,
            isUser,
            isSale,
            isSupport,
        };
    } else {
        return {
            isUser,
        };
    }
};

export const useUploader = () => {
    return async (file) => {
        const extension = last(split(file.name, '.'));
        // console.log('file:', file, extension);
        const {
            result: {url, type, accessUrl},
        } = await api().post(['upload-file'], {
            extension,
        });

        await generalClient
            .put(url)
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Credentials', 'true')
            .set('x-ms-blob-type', 'BlockBlob')
            // .set('x-amz-acl', 'public-read')
            // .withCredentials()
            .set('Content-Type', type)
            .send(file);

        return accessUrl;
    };
};

export const useRegion = (options = {}) => {
    const {} = options;
    const {t: countriesI18n} = useTranslation('countries');
    const swr = useSWRTemplate({
        key: ['region'],
        request: async (_) => {
            const {result} = await api().get(['region']);
            return result;
        },
        defaultValue: [],
    });
    const localedCountries = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: countriesI18n(item.code),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedCountries,
        restrictedCountries: localedCountries.filter((item) =>
            Boolean(item.isRestricted),
        ),
    };
};

export const usePersonTitle = (options = {}) => {
    const {} = options;
    const {t} = useTranslation('personTitle');
    const swr = useSWRTemplate({
        key: ['person-title'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'person-title']);
            return result;
        },
        defaultValue: [],
    });
    const localedTitle = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: t(item.code),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedTitle,
    };
};

export const useOccupation = (options = {}) => {
    const {} = options;
    const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: ['person-occupation'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'person-occupation']);
            return result;
        },
        defaultValue: [],
    });
    const localedOccupation = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: t(item.name),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedOccupation,
    };
};

export const useCompanyIndustry = (options = {}) => {
    const {} = options;
    const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: ['company-industry'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'company-industry']);
            return result;
        },
        defaultValue: [],
    });
    const localedIndustry = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: t(item.name),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedIndustry,
    };
};

export const usePhoneCode = (options = {}) => {
    const {} = options;
    return useSWRTemplate({
        key: ['phone-code'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'phone-code']);
            return result.map((item) => ({
                value: `+${item.phoneCode}`,
                label: `+${item.phoneCode}`,
            }));
        },
        defaultValue: [],
    });
};

export const useCurrency = (options = {}) => {
    const {} = options;
    const {t: currencyT} = useTranslation('currencies');
    const swr = useSWRTemplate({
        key: ['currency'],
        request: async (_) => {
            const {result} = await api().get(['currency-list']);
            return result.map(processCurrency);
        },
        defaultValue: [],
    });
    const localedCurrency = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: currencyT(item.code) + ` (${item.code})`,
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    const platformCurrencyOrder = [
        'EUR',
        'GBP',
        'AUD',
        'NZD',
        'USD',
        'CAD',
        'JPY',
        'SGD',
        'HKD',
    ];
    const platformCurrency = localedCurrency
        .filter((item) => item.isMT4Support)
        .sort(
            (a, b) =>
                platformCurrencyOrder.indexOf(a.code) -
                platformCurrencyOrder.indexOf(b.code),
        );
    return {
        ...swr,
        localedCurrency,
        platformCurrency,
        updateCurrency_: async (id, data) => {
            const {result} = await api().put(
                ['currency', id],
                cleanObjectNilValue(data),
            );
            return result;
        },
    };
};

export const useStates = (options = {}) => {
    const {country} = options;
    const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: country ? ['state', country] : null,
        request: async ([_, _country]) => {
            const {result} = await api().get(['commons', 'state'], {
                countryCode: _country,
            });
            return result;
        },
        defaultValue: [],
    });
    const localedStates = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: item.name + ` (${item.isoCode})`,
            code: item.isoCode,
            value: item.isoCode,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedStates,
    };
};
export const useCities = (options = {}) => {
    const {state, country} = options;
    // const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: state && country ? ['city', state, country] : null,
        request: async ([_, _state, _country]) => {
            const {result} = await api().get(['commons', 'city'], {
                stateCode: _state,
                countryCode: _country,
            });
            return result;
        },
        defaultValue: [],
    });
    const localedCities = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: item.name,
            code: item.name,
            value: item.name,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedCities,
    };
};

export const useCurrencyCutOffTime = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['currency-cut-off-time'],
        request: async ([_]) => {
            const {result} = await api().get([
                'commons',
                'currency-cut-off-time',
            ]);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const sendVerifyCode_ = async ({method}) => {
    const {result} = await api().post(['verify-code'], {
        method,
    });
    return result;
};

export const downloadFile_ = async (
    blobFile,
    name = 'template.xlsx',
    type = 'application/vnd.openxmlformats',
) => {
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(new Blob([blobFile], {type}));
    a.download = name;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const useCryptoNetwork = (options = {}) => {
    return useSWRTemplate({
        key: ['CryptoNetwork'],
        request: async ([_]) => {
            const {result} = await api().get(['commons', 'crypto-type'], {
                code: 'USDT',
            });
            return result;
        },
        defaultValue: {},
    });
};
