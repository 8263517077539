import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Center, HStack} from '@chakra-ui/react';
import LanguageBtn from 'components/LanguageBtn';
import ColorModeBtn from 'components/ColorModeBtn';
import {useColorModeValueKey} from 'hooks/useColors';
import Page from 'components/Page';
import {usePlatformBasicInfo} from 'hooks/datahook/auth';

const GuestLayout = (props) => {
    const {t} = useTranslation('app');
    const {data: basicInfo, isLoading} = usePlatformBasicInfo();
    return (
        <Page title={isLoading ? t('loading') : basicInfo.companyShortName}>
            <Box
                position={'relative'}
                width={'100vw'}
                // bg={useColorModeValueKey('bg')}
            >
                <HStack
                    position={'absolute'}
                    alignItems={'flex-end'}
                    justifyContent={'flex-end'}
                    right={8}
                    top={4}
                    spacing={2}
                    w={'100vw'}
                >
                    <LanguageBtn />
                    <ColorModeBtn />
                </HStack>
                <Center
                    alignItems={'center'}
                    justifyContent={'center'}
                    // position={'absolute'}
                    width={'100vw'}
                    height={'100vh'}
                    // bg={useColorModeValueKey('bg')}
                >
                    <Outlet />
                </Center>
            </Box>
        </Page>
    );
};

export default GuestLayout;
