import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {get} from 'lodash';
import {colorByMode, getReadableTextColor} from 'theme/themeHelper';

const primary = defineStyle((props) => {
    const {colorScheme: c, colorMode, theme} = props;
    const primaryColor = get(
        theme,
        `colors.${colorMode}.primary.${colorMode === 'dark' ? 400 : 500}`,
    );
    const readableTextColor = getReadableTextColor({
        theme,
        backgroundColor: primaryColor,
    });
    return {
        bg: primaryColor,
        color: readableTextColor,
        _dark: {
            bg: primaryColor,
            // color: 'dark.gray.800',
        },
        _hover: {
            bg: `${colorMode}.primary.600`,
            _dark: {
                bg: `${colorMode}.primary.300`,
            },
            _disabled: {
                bg: `${colorMode}.primary.500`,
            },
        },
        _active: {
            bg: `${colorMode}.primary.700`,

            _dark: {
                bg: `${colorMode}.primary.200`,
            },
        },
    };
});

const error = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        bg: `${colorMode}.error.500`,
        color: 'light.gray.50',
        _dark: {
            bg: `${colorMode}.error.400`,
            color: 'dark.gray.800',
        },
        _hover: {
            bg: `${colorMode}.error.600`,
            _dark: {
                bg: `${colorMode}.error.300`,
            },
            _disabled: {
                bg: `${colorMode}.error.500`,
            },
        },
        _active: {
            bg: `${colorMode}.error.700`,

            _dark: {
                bg: `${colorMode}.error.200`,
            },
        },
    };
});
const success = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        bg: `${colorMode}.success.500`,
        color: 'light.gray.50',
        _dark: {
            bg: `${colorMode}.success.400`,
            color: 'dark.gray.800',
        },
        _hover: {
            bg: `${colorMode}.success.600`,
            _dark: {
                bg: `${colorMode}.success.300`,
            },
            _disabled: {
                bg: `${colorMode}.success.500`,
            },
        },
        _active: {
            bg: `${colorMode}.success.700`,

            _dark: {
                bg: `${colorMode}.success.200`,
            },
        },
    };
});

const solid = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        bg: colorByMode('brighterBg', colorMode),
        _hover: {
            bg: colorByMode('bg', colorMode),
        },
        _active: {
            bg: colorByMode('bg', colorMode),
        },
    };
});

const outline = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        borderColor: 'gray.300',
    };
});

const Button = defineStyleConfig({
    variants: {primary, solid, outline, success, error},
});
export default Button;
