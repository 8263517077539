import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const SettingExcludeLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const tabs = [
        {
            label: t('settingsExcludedGroups'),
            value: 'settingsExcludedGroups',
            path: adminPaths.settingsExcludedGroups,
        },
        {
            label: t('settingsExcludedAccounts'),
            value: 'settingsExcludedAccounts',
            path: adminPaths.settingsExcludedAccounts,
        },
    ];
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;
    return (
        <Stack w={'95vw'} m={'auto'} spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default SettingExcludeLayout;
