import {get} from 'lodash';

export const processSetting = (rawData) => {
    return {
        id: get(rawData, 'id'),
        isMobileVerified: Boolean(get(rawData, 'isMobileVerified')),
        isEmailVerified: Boolean(get(rawData, 'isEmailVerified')),
        enableEmailFact: Boolean(get(rawData, 'enableEmailFact')),
        enableSmsFact: Boolean(get(rawData, 'enableSmsFact')),
        enableOtpFact: Boolean(get(rawData, 'enableOtpFact')),
        smsNotification: Boolean(get(rawData, 'smsNotification')),
        loginTwoFactor: Boolean(get(rawData, 'loginTwoFactor')),
        emailNotification: Boolean(get(rawData, 'emailNotification')),
        stockColor: get(rawData, 'stockColor'),
        locale: get(rawData, 'locale'),
        timezone: get(rawData, 'timezone'),
        twoFactor: Boolean(get(rawData, 'twoFactor')),
        allowDevelop: Boolean(get(rawData, 'allowDevelop')),
        enableCryptoWithdrawal: Boolean(get(rawData, 'enableCryptoWithdrawal')),
        preferredPlatformCurrency:
            get(rawData, 'preferredPlatformCurrency') || 'USD',
    };
};
